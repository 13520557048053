import request from '@/utils/request'

export function getMember(parameter) {
  let result = request({
    url: '/member',
    method: 'get',
    params: parameter,
  });
  return result;
}

export function getMerchantMemberByMobile(parameter) {
  return request({
    url: '/member/query/merchant',
    method: 'get',
    params: parameter,
  });
}

export function putMember(parameter) {
  return request({
    url: '/member',
    method: 'PUT',
    data: parameter
  })
}

export function queryMembers(parameter) {
  return request({
    url: '/member',
    method: 'post',
    data: parameter
  })
}

export function upstate(parameter) {
  return request({
    url: '/member/status',
    method: 'post',
    data: parameter
  })
}

export function addShareMember(parameter) {
  return request({
    url: '/member/share/add',
    method: 'PUT',
    data: parameter
  })
}

export function moveShareMember(parameter) {
  return request({
    url: '/member/move',
    method: 'post',
    data: parameter
  })
}

export function deleteShareMember(parameter) {
  return request({
    url: '/member/delete/' + parameter.id,
    method: 'delete',
  })
}

export function queryMemberList(parameter) {
  return request({
    url: '/member/list',
    method: 'post',
    data: parameter
  })
}

export function getMemberDashboard(parameter) {
  return request({
    url: '/member/statistics',
    method: 'get',
    params: parameter
  })
}

export function queryApplyList(parameter) {
  return request({
    url: '/merchant/page',
    method: 'post',
    data: parameter
  })
}


export function getApplyDashboard(parameter) {
  return request({
    url: '/merchant/audit/statistics',
    method: 'get',
    params: parameter
  })
}

export function updateApplyStatus(parameter) {
  return request({
    url: '/merchant/audit',
    method: 'post',
    data: parameter
  })
}

export function deleteApply(parameter) {
  return request({
    url: '/merchant/audit/del/' + parameter.id,
    method: 'delete',
  })
}

export function queryMerchant(parameter) {
  return request({
    url: '/merchant/manage/page',
    method: 'post',
    data: parameter
  })
}

//更新商户的直推间推间间推
export function updateSpreaderThree(parameter) {
  return request({
    url: '/member/update/spreader/three',
    method: 'post',
    data: parameter
  })
}

export function getMerchantDashboard(parameter) {
  return request({
    url: '/merchant/manage/statistics',
    method: 'get',
    params: parameter
  })
}

export function deleteMerchant(parameter) {
  return request({
    url: '/member/delete/' + parameter.id,
    method: 'delete',
  })
}

export function putMerchant(parameter) {
  // debugger;
  // console.log(parameter)
  return request({
    url: '/merchant/update/merchant',
    method: 'post',
    data: parameter
  })
}

export function updateMerchantCash(parameter) {
  return request({
    url: '/member/update/cash',
    method: 'post',
    data: parameter
  })
}

export function transferMerchant(parameter) {
  return request({
    url: '/wealth/quota/transfer',
    method: 'post',
    data: parameter
  })
}
export function transferMerchantCash(parameter) {
  return request({
    url: '/member/transfer/cash',
    method: 'post',
    data: parameter
  })
}

export function addMerchant(parameter) {
  return request({
    url: '/wealth/quota/add',
    method: 'post',
    data: parameter
  })
}

export function addMerchantCash(parameter) {
  return request({
    url: '/member/add/cash',
    method: 'post',
    data: parameter
  })
}

export function getMerchantInfo(parameter) {
  return request({
    url: '/member/query/merchant',
    method: 'get',
    params: parameter
  })
}

export function putMerchantRemark(parameter) {
  return request({
    url: '/merchant/remark/add',
    method: 'put',
    data: parameter
  })
}

export function queryMerchantRemark(parameter) {
  return request({
    url: '/merchant/remark/list',
    method: 'get',
    params: parameter
  })
}

export function checkAuthCode(parameter) {
  return request({
    url: '/merchant/check/code',
    method: 'get',
    params: parameter
  })
}

export function queryMemberDiscount(parameter) {
  return request({
    url: '/wealth/quota/wait/page',
    method: 'get',
    params: parameter
  })
}

export function getMemberDiscountStatistics(parameter) {
  return request({
    url: '/wealth/quota/wait/statistics',
    method: 'get',
    params: parameter
  })
}

export function  queryDisountRecord(parameter) {
  return request({
    url: '/wealth/quota/record/v2',
    method: 'get',
    params: parameter
  })
}

export function  deleteDisountRecord(parameter) {
  return request({
    url: '/wealth/quota/order/delete/' + parameter.id,
    method: 'post',
  })
}

export function  putMobileMember(parameter) {
  return request({
    url: '/member/add',
    method: 'put',
    data: parameter
  })
}

export function deleteMerchantAudit(parameter) {
  return request({
    url: '/merchant/audit/del/' + parameter.id,
    method: 'delete',
  })
}

export function putMemberTransfer(parameter) {
  return request({
    url: '/member/update/mobile',
    method: 'post',
    data: parameter
  })
}

export function handelJieChu(parameter) {
  // debugger;
  return request({
    url: '/merchant/secure',
    method: 'post',
    data: parameter
  })
}

export function getContractById(parameter) {
  return request({
    url: '/merchant/merchant/contract/' + parameter.id,
    method: 'get',
  })
}